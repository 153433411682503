<template>
    <div class="main-cont">
      <div v-bind:class="cssClass">
        <div class="container-fluid header-background">
            <div class="container-fluid header-background-2">
                <header class="header">
                    <div class="row align-items-center row-header">
                        <a href="https://www.mos.ru/" target="_blank" class="col header-logo logo--PM">
                            <img src="/images/logo/header/header_logo_PM.png" alt="" class="logo--PM">
                        </a>
                        <router-link class="col header-logo logo--gorod" to="/">
                            <img src="/images/logo/header/header_logo_gorod.png" alt="" class="logo--gorod">
                        </router-link>
                        <div class="col col-md-4 mobile-language" v-bind:class="langnoneM">
                            <LocaleSwitcher code="ru" name="Ru"/>
                            <LocaleSwitcher code="en" name="Eng"/>
                        </div>
                        <div class="col-7 header-menu js-header-menu">
                            <a :href="links[$i18n.locale].program" class="menu-text js-menu-text--info">{{$t('navbar.about_show')}}</a>
                            <router-link to="/catalog" class="menu-text js-menu-text--programm">{{$t('navbar.catalog')}}</router-link>
                            <a :href="links[$i18n.locale].speakers" class="menu-text js-menu-text--media_center">{{$t('navbar.media_center')}}</a>
                            <a :href="links[$i18n.locale].broadcast" class="menu-text js-menu-text--video">{{$t('navbar.ar')}}</a>
                            <a v-if="userSigned" @click="signOut" class="menu-text">{{$t('navbar.exit')}}</a>
                        </div>
                        <div class="col header-language" v-bind:class="langnone">
                          <LocaleSwitcher code="ru" name="Ru"/>
                          <LocaleSwitcher code="en" name="Eng"/>
                        </div>
                        <a href="https://www.mos.ru/moi-raion/" target="_blank" class="col header-logo logo-my_on">
                            <img src="/images/logo/header/header_logo_my_on.png" alt="" class="logo--gorod">
                        </a>
                        <div class="menu__box">
                            <router-link to="/login" class="menu__item" >{{$t('navbar.registration')}}</router-link>
                            <a :href="links[$i18n.locale].program"  class="menu__item">{{$t('navbar.about_show')}}</a>
                            <router-link to="/catalog" class="menu__item">{{$t('navbar.catalog')}}</router-link>
                            <a :href="links[$i18n.locale].speakers" class="menu__item">{{$t('navbar.media_center')}}</a>
                            <a :href="links[$i18n.locale].broadcast" class="menu__item">{{$t('navbar.ar')}}</a>
                            <a v-if="userSigned" @click="signOut" class="menu__item">{{$t('navbar.exit')}}</a>
                            <div class="mobile-icon--row">
                                <a href="https://www.mos.ru/" target="_blank" class="mobile-icon--PM">
                                    <img src="images/logo/header/header_logo_PM.png" alt="" class="logo--PM">
                                </a>
                                <a href="https://www.mos.ru/moi-raion/" target="_blank"  class="mobile-icon--my_on">
                                    <img src="images/logo/header/my_on-mobile.png" alt="" class="logo--gorod">
                                </a>
                            </div>
                        </div>
                        <div class="col col-md-3 row hamburger-menu">
                            <a href="https://www.mos.ru/moi-raion/" target="_blank" class="col header-logo-mobile logo-my_on">
                                <img src="/images/logo/header/header_logo_my_on.png" alt="" class="logo--gorod">
                            </a>
                            <div class="col menu-mobile">
                                <label class="menu__btn"  @click.stop="activeMenu()">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    </div>
    <div id="app">
        <router-view/>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from "@/LocaleSwitcher";
import store from "@/store";

export default {
  name: "App",
  components: {
    LocaleSwitcher,
  },
  watch: {
    '$route.path'() {
      if (this.$route.path.startsWith('/expo')){
        document.querySelector('.main-cont').classList.add('inscene');
        document.querySelector('.header-background-2').classList.add('expo');
      }else{
        document.querySelector('.main-cont').classList.remove('inscene');
        document.querySelector('.header').classList.remove('griz');
        document.querySelector('.header-background-2').classList.remove('expo');
      }
      document.querySelectorAll('.menu__item').forEach((item) =>{
        item.classList.remove('active')
      });
      document.querySelector('.menu__box').classList.remove('active');
      document.querySelector('.mobile-icon--row').classList.remove('active');
      document.querySelector('.menu__btn').classList.remove('active');
    }
  },
  computed: {
    userSigned() {
      return store.state.user && !store.state.user.isAnonymous;
    },
    cssClass() {
      if (this.$route.path.startsWith('/expo'))
        return 'container-fluid main_body inscene'
      else
        return 'container-fluid main_body noinscene'
    },
    langnoneM() {
      if (this.$route.path.startsWith('/expo'))
        return 'col col-md-4 mobile-language d-none'
      else
        return 'col col-md-4 mobile-language'
    },
    langnone() {
      if (this.$route.path.startsWith('/expo'))
        return 'col header-language d-none'
      else
        return 'col header-language'
    },
  },
  methods: {
    signOut() {
      store.dispatch('signOut')
    },
    activeMenu(){
        let chekbox = document.querySelectorAll('.checkselect-popup');
        if(chekbox.length > 0){
            chekbox.forEach((item) => {
                console.log("Ghbdtn cwer")
                if(getComputedStyle(item).display == "block"){
                    item.style.display = "none";
                }
            });
        }
        document.querySelectorAll('.menu__item').forEach((item) =>{
            item.classList.toggle('active')
        });
        document.querySelector('.menu__box').classList.toggle('active');
        document.querySelector('.mobile-icon--row').classList.toggle('active');
        document.querySelector('.menu__btn').classList.toggle('active');
    },
  },
  mounted() {
    this.$log.info(`Active locale: ${this.$i18n.locale}`);
    window.addEventListener('click',() => {
      document.querySelectorAll('.menu__item').forEach((item) =>{
          item.classList.remove('active')
      });
      document.querySelector('.menu__box').classList.remove('active');
      document.querySelector('.mobile-icon--row').classList.remove('active');
      document.querySelector('.menu__btn').classList.remove('active');
    }, false);
  },
  data(){
    return{
      links:{
        ru:{
          speakers: "https://mrexpo.ru/speakers/",
          program: "https://mrexpo.ru/program/",
          broadcast: "https://mrexpo.ru/archive/",
        },
        en:{
          speakers: "https://mrexpo.ru/en/speakers/",
          program: "https://mrexpo.ru/en/program/",
          broadcast: "https://mrexpo.ru/en/archive/",
        }
      }
    }
  }
}
</script>
<style scoped>
.main-cont.inscene{
  touch-action: none;
}
@media(max-height: 650px) and (min-width: 550px){
  .header-background-2.expo {
    top: -65px;
  }

}
.header-background-2 {
  top: -42px;
}
</style>
