import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from "firebase/app";
import "firebase/auth";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
     {
          path: '/',
          name: 'Home',
          component: () => import('@/components/BabylonHome.vue'),
          meta: {
          }
     },
     {
          path: '/expo',
          name: 'Expo',
          component: () => import('@/components/BabylonHome.vue'),
          meta: {
               requiresAnonymousOrReal: true,
               scrollToTop: true,
          }
     },
     {
          path: '/login',
          name: 'Login',
          component: () => import('@/components/Login.vue'),
          meta: {
               scrollToTop: true,
          }
     },
     {
          path: '/phonelogin',
          name: 'PhoneLogin',
          component: () => import('@/components/PhoneLogin.vue'),
     },
     {
          path: '/info',
          name: 'ExpoLandingInfo',
          component: () => import('@/components/ExpoLandingInfo.vue'),
     },
     {
          path: '/support',
          name: 'Support',
          component: () => import('@/components/SupportChat.vue'),
          meta: {
               requiresAuth: true,
          },
     },
     {
          path: '/catalog',
          name: 'Catalog',
          component: () => import('@/components/ExpoCatalog.vue'),
          meta: {
               requiresAnonymousOrReal: true,
          },
     },
     {
          path: '/catalog/:id/:objectId/details',
          name: 'ObjectDetails',
          component: () => import('@/components/ExpoCatalogDetails.vue'),
          meta: {
               requiresAnonymousOrReal: true,
               scrollToTop: true,
          },
          props(route) {
               return {
                    object: store.getters.expoObjectById(route.params.id),
                    ...route.params,
               }
          },
     },
     {
          path: '/studio-catalog',
          name: 'StudioCatalog',
          component: () => import('@/components/ExpoStudioCatalog.vue'),
          meta: {
               requiresAnonymousOrReal: true,
          },
     },
     {
          path: '/studio-catalog/:id/details',
          name: 'StudioDetails',
          component: () => import('@/components/ExpoStudioCatalogDetails.vue'),
          meta: {
               requiresAnonymousOrReal: true,
               scrollToTop: true,
          },
          props(route) {
               return {
                    manufacturer: store.getters.expoManufacturerById(route.params.id),
                    ...route.params,
               }
          },
     },
]

const router = new VueRouter({
     routes,
     scrollBehavior (to, from, savedPosition) {
        if (to.meta && to.meta.scrollToTop)
             return {x: 0, y: 0}
        return savedPosition
     },
     base: process.env.BASE_URL,
})

router.beforeEach(async (to, from, next) => {
     const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
     const requiresAnonymousOrReal = to.matched.some(record => record.meta.requiresAnonymousOrReal);
     // Needs complete Auth
     // if (false)
     // {
     //      const user = await firebase.getCurrentUser();
     //      if (!user || user.isAnonymous || user.isAnonymous === undefined) {
     //           await router.push({
     //                name: "Login",
     //                query: {
     //                     next: to.name
     //                }
     //           })
     //      } else {
     //           next();
     //      }
     //      // Needs at least Anonymous Auth
     // } else if (requiresAnonymousOrReal) {
     //      if (!await firebase.getCurrentUser()) { 
               await firebase.auth().signInAnonymously();
               next();
          // } else {
          //      next();
          // }
          // Doesn't matter
     // } else {
     //      next();
     // }
});

router.afterEach((to, from) => {
     if ((to && to.query && to.query.lang === 'en' || to.query.lang === 'ru') &&
       router.app.$i18n.locale !== to.query.lang) {
          localStorage.setItem('lang', to.query.lang);
          router.app.$i18n.locale = to.query.lang;
     }
     //router.app.$i18n.locale = localStorage.getItem("eng");
     if (from.name === "Catalog" && to.name === "Expo" || from.name === "Home" && to.name === "Expo" || from.name === "Login" && to.name === "Expo") {
          router.go();
     }
});
export default router
